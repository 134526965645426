import styled from '@emotion/styled';
import IconButton from '@mui/material/IconButton';
import Switch from '@mui/material/Switch';
import {ptBR as corePtBR} from '@mui/material/locale';
import {createTheme} from '@mui/material/styles';
import {ptBR} from '@mui/x-data-grid';
import dayjs from 'dayjs';
import {Nunito} from 'next/font/google';
import {colors} from './customColors';
import {customTypography} from './customTypography';
import {themeForm} from './theme/theme-form';

const font = Nunito({subsets: ['latin'], variable: '--default-font'});

function getButtonContainedBackgroundHover(color) {
  return `linear-gradient(0deg, ${colors.neutral[0]}2F 0%, ${colors.neutral[0]}2F 100%), ${color}`;
}
function getButtonContainedBackgroundActive(color) {
  return `linear-gradient(0deg, ${colors.neutral[1000]}2F 0%, ${colors.neutral[1000]}2F 100%), ${color}`;
}

export default function getTheme() {
  const smallSize = '16px';
  const mediumSize = '20px';
  const largeSize = '24px';
  dayjs.locale('pt-br');
  return {
    theme: createTheme(
      {
        palette: {
          ...colors,
          error: {
            ...colors.red.proper,
          },
          white: {
            main: colors.neutral[0],
          },
          background: {
            default: colors.neutral[100],
          },
        },
        shadows: [
          'none',
          '0px 1px 2px 0px rgba(0, 0, 0, 0.30), 0px 1px 3px 1px rgba(0, 0, 0, 0.15)',
          '0px 1px 2px 0px rgba(0, 0, 0, 0.30), 0px 2px 6px 2px rgba(0, 0, 0, 0.15)',
          '0px 4px 8px 3px rgba(0, 0, 0, 0.15), 0px 1px 3px 0px rgba(0, 0, 0, 0.30)',
          '0px 6px 10px 4px rgba(0, 0, 0, 0.15), 0px 2px 3px 0px rgba(0, 0, 0, 0.30)',
          '0px 8px 12px 6px rgba(0, 0, 0, 0.15), 0px 4px 4px 0px rgba(0, 0, 0, 0.30)',
          '0px 10px 14px 8px rgba(0, 0, 0, 0.15), 0px 6px 6px 0px rgba(0, 0, 0, 0.30)',
          '0px 12px 18px 10px rgba(0, 0, 0, 0.15), 0px 8px 8px 0px rgba(0, 0, 0, 0.30)',
          '0px 14px 22px 12px rgba(0, 0, 0, 0.15), 0px 10px 10px 0px rgba(0, 0, 0, 0.30)',
          ...Array(11).fill('none'),
        ],
        typography: {
          ...customTypography,
        },
        components: {
          MuiButton: {
            styleOverrides: {
              root: {
                textTransform: 'none',
                fontWeight: 600,
              },
              sizeSmall: {
                fontSize: '12px',
                height: '24px',
                '& .MuiButton-startIcon, & .MuiButton-endIcon': {
                  '& > *:nth-of-type(1)': {fontSize: smallSize},
                },
              },
              sizeMedium: {
                height: '32px',
                '& .MuiButton-startIcon, & .MuiButton-endIcon': {
                  '& > *:nth-of-type(1)': {fontSize: mediumSize},
                },
              },
              sizeLarge: {
                fontSize: '16px',
                height: '40px',
                '& .MuiButton-startIcon, & .MuiButton-endIcon': {
                  '& > *:nth-of-type(1)': {fontSize: largeSize},
                },
              },
              contained: {
                color: colors.neutral[0],
                backgroundColor: colors.primary.main,
                border: `1px solid ${colors.primary.main}`,
                boxShadow: 'none',
                '&:hover': ({theme}) => ({
                  boxShadow: theme.shadows[3],
                  backgroundColor: getButtonContainedBackgroundHover(
                    colors.primary.main
                  ),
                }),
                '&:active': {
                  background: getButtonContainedBackgroundActive(
                    colors.primary.main
                  ),
                },
                '&:disabled': {
                  backgroundColor: colors.neutral[400],
                  color: colors.neutral[600],
                  borderColor: colors.neutral[400],
                },
              },
              containedError: {
                backgroundColor: colors.red.proper.main,
                borderColor: colors.red.proper.main,
                '&:hover': {
                  background: getButtonContainedBackgroundHover(
                    colors.red.proper.main
                  ),
                },
                '&:active': {
                  background: getButtonContainedBackgroundActive(
                    colors.red.proper.main
                  ),
                },
              },
              outlined: {
                color: colors.primary.main,
                backgroundColor: colors.neutral[0],
                borderColor: colors.neutral[400],
                '&:hover': {
                  backgroundColor: `${colors.primary.main}14`,
                  borderColor: colors.neutral[400],
                },
                '&:active': {
                  borderColor: colors.primary.main,
                },
                '&:disabled': {
                  color: `${colors.neutral[1000]}61`,
                  borderColor: `${colors.neutral[950]}1F`,
                },
              },
              outlinedError: {
                color: colors.red.proper.main,
                backgroundColor: colors.neutral[0],
                '&:hover': {
                  backgroundColor: `${colors.red.highlight.main}14`,
                  borderColor: colors.neutral[400],
                },
                '&:active': {
                  borderColor: colors.red.proper.main,
                  backgroundColor: `${colors.red.highlight.main}1F`,
                },
              },
              text: {
                color: colors.primary.main,
                backgroundColor: 'inherit',
                borderRadius: 0,
                '&:hover': {
                  backgroundColor: `${colors.primary.light}14`,
                },
                '&:active': {
                  backgroundColor: `${colors.primary.light}1F`,
                },
                '&:disabled': {
                  color: `${colors.neutral[1000]}61`,
                },
              },
              textError: {
                color: colors.red.proper.main,
                '&:hover': {
                  backgroundColor: `${colors.red.highlight.main}14`,
                },
                '&:active': {
                  backgroundColor: `${colors.red.highlight.main}1F`,
                },
              },
            },
            variants: [
              {
                props: {variant: 'elevated'},
                style: ({theme, ownerState}) => {
                  const primary = ownerState.color === 'primary';
                  const color = primary
                    ? colors.primary.main
                    : colors.red.proper.main;
                  const bgcolor = primary
                    ? colors.neutral[100]
                    : colors.red.highlight.light;
                  const bgcolorSelector = primary
                    ? colors.primary.main
                    : colors.red.proper.main;
                  return {
                    color: color,
                    backgroundColor: bgcolor,
                    boxShadow: theme.shadows[3],
                    '&:hover': {
                      backgroundColor: `${bgcolorSelector}14`,
                    },
                    '&:active': {
                      backgroundColor: `${bgcolorSelector}1F`,
                    },
                    '&:disabled': {
                      borderRadius: 0,
                      boxShadow: theme.shadows[0],
                      color: `${colors.neutral[950]}61`,
                      backgroundColor: `${colors.neutral[950]}1F`,
                    },
                  };
                },
              },
            ],
          },
          MuiInputBase: {
            styleOverrides: {
              root: {
                backgroundColor: colors.neutral[0],
              },
              input: {
                '&::placeholder': {
                  color: colors.neutral[800],
                },
              },
            },
          },
          MuiOutlinedInput: {
            styleOverrides: {
              notchedOutline: {
                borderColor: colors.neutral[400],
              },
              root: {
                '&:not(.Mui-disabled):hover:not(.Mui-focused):not(.Mui-error) .MuiOutlinedInput-notchedOutline':
                  {
                    borderColor: colors.neutral[1000],
                  },
                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                  border: `2px solid ${colors.primary.main}`,
                },
                '&.Mui-error .MuiOutlinedInput-notchedOutline': {
                  border: `2px solid ${colors.red.proper.main}`,
                },
                '&.Mui-disabled .MuiOutlinedInput-notchedOutline': {
                  borderColor: `${colors.neutral[950]}33`,
                },
                '&.Mui-disabled': {
                  color: `${colors.neutral[1000]}61`,
                  opacity: 1,
                  cursor: 'not-allowed',
                },
              },
            },
          },
          MuiCard: {
            styleOverrides: {
              root: {
                borderRadius: '8px',
                boxShadow: 'none',
                backgroundColor: 'white',
                border: `1px solid ${colors.neutral[400]}`,
              },
            },
          },
          MuiCardHeader: {
            styleOverrides: {
              root: {},
              title: {
                fontSize: '20px',
                fontWeight: 700,
              },
            },
          },
          ...themeForm,
          MuiDrawer: {
            styleOverrides: {
              paper: {
                backgroundColor: colors.primary.dark,
                color: 'white',
              },
            },
          },
          MuiTypography: {
            styleOverrides: {
              ...customTypography,
            },
          },
          MuiLink: {
            styleOverrides: {
              root: {
                textTransform: 'none',
                underline: 'none',
                textDecoration: 'none',
                ...customTypography.body_normal_medium,
                fontWeight: 600,
              },
            },
          },
          MuiDialogTitle: {
            defaultProps: {
              PaperProps: {
                sx: {
                  backgroundColor: colors.neutral[0],
                },
              },
            },
            styleOverrides: {
              root: {
                fontWeight: 700,
              },
            },
          },
          MuiDataGrid: {
            styleOverrides: {
              root: {
                borderRadius: 0,
                border: 0,
              },
              columnHeaders: {
                backgroundColor: colors.neutral[400],
                borderRadius: 0,
              },
              footerContainer: {
                border: 0,
              },
            },
          },
          MuiAutocomplete: {
            styleOverrides: {
              popupIndicator: {
                '& svg': {fontSize: largeSize},
              },
              clearIndicator: {
                '& svg': {fontSize: mediumSize},
              },
            },
          },
          MuiSvgIcon: {
            styleOverrides: {
              root: {fontSize: mediumSize},
              fontSizeSmall: {fontSize: smallSize},
              fontSizeMedium: {fontSize: mediumSize},
              fontSizeLarge: {fontSize: largeSize},
            },
          },
        },
      },
      ptBR,
      corePtBR
    ),
    fontClass: font,
  };
}

export function DateTimeInputTheme() {
  return {
    theme: createTheme({
      typography: {
        ...customTypography,
      },
      components: {
        MuiPaper: {
          styleOverrides: {
            root: {
              height: 334,
            },
          },
        },
        MuiPickersLayout: {
          styleOverrides: {
            root: {
              height: 380,
            },
            contentWrapper: {
              gridRow: 1,
            },
          },
        },
        MuiMultiSectionDigitalClock: {
          styleOverrides: {
            root: {
              height: 300,
              maxHeight: '84%',
            },
          },
        },
        MuiDialogActions: {
          styleOverrides: {
            root: {
              padding: 0,
              position: 'absolute',
              bottom: 5,
              right: 0,
            },
          },
        },
        MuiYearCalendar: {
          styleOverrides: {
            root: {
              height: 250,
            },
          },
        },
      },
    }),
  };
}

export function CustomSelectTheme({
  height,
  width,
  backgroundColor,
  typography,
}) {
  return createTheme({
    components: {
      ...customTypography,
      MuiInputBase: {
        styleOverrides: {
          input: {
            '&:focus': {
              backgroundColor: 'inherit !important',
            },
          },
        },
      },
      MuiMenuItem: {
        styleOverrides: {
          root: {
            ...customTypography.body_normal_large, // Aplica a tipografia nas opções do menu
          },
        },
      },
      MuiFormControl: {
        styleOverrides: {
          root: {
            backgroundColor:
              backgroundColor || colors.neutral[0] + ' !important',
          },
        },
      },
      MuiFormLabel: {
        styleOverrides: {
          root: {
            ...customTypography.title_small,
            color: colors.neutral[1000],
            marginTop: -12,
            '&[data-shrink="true"]': {
              display: 'none',
            },
          },
        },
      },
      MuiAutocomplete: {
        styleOverrides: {
          input: {
            padding: '0 !important',
          },
        },
      },
      MuiSelect: {
        styleOverrides: {
          select: {
            ...customTypography[typography || 'title_large'],
            padding: 0,
          },
          root: {
            height: height || 'auto',
            width: width || 'auto',
          },
        },
      },
    },
  });
}

export const CustomIconButton = styled(IconButton)(() => ({padding: 0}));

export const CustomSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({customStyles}) => ({
  width: customStyles.width,
  height: customStyles.height,
  padding: 0,
  display: 'flex',
  '&:active': {},
  '& .MuiSwitch-switchBase': {
    padding: customStyles.width / customStyles.height === 1.75 ? 1.75 : 2.5,
    '&.Mui-checked': {
      transform: `translateX(${customStyles.width * 0.425}px)`,
      color: colors.neutral[0],
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: colors.primary.main,
      },
    },
  },
  '& .MuiSwitch-thumb': {
    boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
    width: customStyles.height * 0.75,
    height: customStyles.height * 0.75,
    borderRadius: 13,
  },
  '& .MuiSwitch-track': {
    borderRadius: 13,
    backgroundColor: colors.neutral[500],
    border: `1.5px solid ${colors.neutral[800]}`,
    boxSizing: 'border-box',
  },
}));
export function CustomLinearProgressTheme(progressEmptyColor, progressBgColor) {
  return createTheme({
    components: {
      MuiLinearProgress: {
        styleOverrides: {
          root: {
            backgroundColor: progressEmptyColor,
          },
          bar: {
            backgroundColor: progressBgColor,
          },
        },
      },
    },
  });
}
