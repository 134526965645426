// -2 lighter = lighter
// -1 light = light
// regular = main
// +1 dark = dark
// +2 darker = darker

export const colors = {
  primary: {
    lighter: '#466AF7',
    light: '#264FDD',
    main: '#093FCF',
    dark: '#002486',
    darker: '#001354',
  },
  neutral: {
    0: '#FFFFFF',
    50: '#FAFBFF',
    75: '#F9FAFC',
    100: '#F4F6FA',
    200: '#EDEFF5',
    300: '#E6E8F0',
    400: '#D8DAE5',
    500: '#C1C4D6',
    600: '#8F95B2',
    700: '#696F8C',
    800: '#474D66',
    900: '#252836',
    950: '#1A1D26',
    1000: '#000000',
  },
  blue: {
    proper: {
      light: '#2B62F3',
      main: '#083FCF',
      dark: '#0F3699',
    },
    // atencao para a chave "light" e não "highlight"
    light: {
      light: '#F3F6FF',
      main: '#E4ECFF',
      dark: '#CDDBFF',
    },
  },
  green: {
    proper: {
      light: '#41D19B',
      main: '#17AE75',
      dark: '#088A59',
    },
    highlight: {
      light: '#F5FBF8',
      main: '#EEF8F4',
      dark: '#DCF2EA',
    },
  },
  red: {
    proper: {
      light: '#F05252',
      main: '#DA2424',
      dark: '#A70F0F',
    },
    highlight: {
      light: '#FDF4F4',
      main: '#F9DADA',
      dark: '#F4B6B6',
    },
  },
  yellow: {
    proper: {
      light: '#F8C74E',
      main: '#F6B941',
      dark: '#ED9419',
    },
    highlight: {
      light: '#FFFAF1',
      main: '#FFEFD2',
      dark: '#FFDFA6',
    },
  },
  orange: {
    proper: {
      light: '#F78758',
      main: '#F67741',
      dark: '#EC6226',
    },
    highlight: {
      light: '#FDF1EA',
      main: '#F9E5DA',
      dark: '#F2D5C4',
    },
  },
  purple: {
    proper: {
      light: '#801DE3',
      main: '#6C08CF',
      dark: '#5B02B4',
    },
    highlight: {
      light: '#F8F2FF',
      main: '#F1E4FF',
      dark: '#E3CCFC',
    },
  },
};
