import {Inter, Manrope} from 'next/font/google';

export const fontPrimary = Inter({
  variable: '--default-font',
  weight: ['400', '600', '700'],
  subsets: ['latin'],
});
export const fontSecondary = Manrope({
  weight: ['700'],
  subsets: ['latin'],
});

export const customTypography = {
  fontFamily: ['sans-serif', 'Arial', fontPrimary.style.fontFamily],
  display_large: {
    fontFamily: ['sans-serif', 'Arial', fontSecondary.style.fontFamily],
    fontSize: 56,
    fontWeight: 700,
    lineHeight: '64px',
  },
  display_medium: {
    fontFamily: ['sans-serif', 'Arial', fontSecondary.style.fontFamily],
    fontSize: 48,
    fontWeight: 700,
    lineHeight: '56px',
  },
  display_small: {
    fontFamily: ['sans-serif', 'Arial', fontSecondary.style.fontFamily],
    fontSize: 36,
    fontWeight: 700,
    lineHeight: '44px',
  },
  headline_large: {
    fontFamily: ['sans-serif', 'Arial', fontSecondary.style.fontFamily],
    fontSize: 32,
    fontWeight: 700,
    lineHeight: '40px',
  },
  headline_medium: {
    fontFamily: ['sans-serif', 'Arial', fontSecondary.style.fontFamily],
    fontSize: 28,
    fontWeight: 700,
    lineHeight: '36px',
  },
  headline_small: {
    fontFamily: ['sans-serif', 'Arial', fontSecondary.style.fontFamily],
    fontSize: 24,
    fontWeight: 700,
    lineHeight: '32px',
  },
  title_large: {
    fontFamily: ['sans-serif', 'Arial', fontSecondary.style.fontFamily],
    fontSize: 20,
    fontWeight: 700,
    lineHeight: '28px',
  },
  title_medium: {
    fontFamily: ['sans-serif', 'Arial', fontSecondary.style.fontFamily],
    fontSize: 16,
    fontWeight: 700,
    lineHeight: '24px',
  },
  title_small: {
    fontFamily: ['sans-serif', 'Arial', fontSecondary.style.fontFamily],
    fontSize: 14,
    fontWeight: 700,
    lineHeight: '20px',
  },
  label_large: {
    fontFamily: ['sans-serif', 'Arial', fontPrimary.style.fontFamily],
    fontSize: 16,
    fontWeight: 600,
    lineHeight: '24px',
  },
  label_medium: {
    fontFamily: ['sans-serif', 'Arial', fontPrimary.style.fontFamily],
    fontSize: 14,
    fontWeight: 600,
    lineHeight: '20px',
  },
  label_small: {
    fontFamily: ['sans-serif', 'Arial', fontPrimary.style.fontFamily],
    fontSize: 12,
    fontWeight: 600,
    lineHeight: '16px',
  },
  label_tiny: {
    fontFamily: ['sans-serif', 'Arial', fontPrimary.style.fontFamily],
    fontSize: 10,
    fontWeight: 600,
    lineHeight: '12px',
  },
  body_normal_large: {
    fontFamily: ['sans-serif', 'Arial', fontPrimary.style.fontFamily],
    fontSize: 16,
    fontWeight: 400,
    lineHeight: '24px',
  },
  body_normal_medium: {
    fontFamily: ['sans-serif', 'Arial', fontPrimary.style.fontFamily],
    fontSize: 14,
    fontWeight: 400,
    lineHeight: '20px',
  },
  body_normal_small: {
    fontFamily: ['sans-serif', 'Arial', fontPrimary.style.fontFamily],
    fontSize: 12,
    fontWeight: 400,
    lineHeight: '16px',
  },
  body_normal_tiny: {
    fontFamily: ['sans-serif', 'Arial', fontPrimary.style.fontFamily],
    fontSize: 10,
    fontWeight: 400,
    lineHeight: '12px',
  },
  body_bold_large: {
    fontFamily: ['sans-serif', 'Arial', fontPrimary.style.fontFamily],
    fontSize: 16,
    fontWeight: 700,
    lineHeight: '24px',
  },
  body_bold_medium: {
    fontFamily: ['sans-serif', 'Arial', fontPrimary.style.fontFamily],
    fontSize: 14,
    fontWeight: 700,
    lineHeight: '20px',
  },
  body_bold_small: {
    fontFamily: ['sans-serif', 'Arial', fontPrimary.style.fontFamily],
    fontSize: 12,
    fontWeight: 700,
    lineHeight: '16px',
  },
  body_bold_tiny: {
    fontFamily: ['sans-serif', 'Arial', fontPrimary.style.fontFamily],
    fontSize: 10,
    fontWeight: 700,
    lineHeight: '12px',
  },
};
